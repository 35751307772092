import { useRef, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../features/authentication/hooks/useAuth";

export const Register = () => {
    const { signUpUser } = useAuth();
    const navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();

    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        setLoading(true);
        setErrorMessage('');

        try {
            await signUpUser(emailRef.current.value, passwordRef.current.value);
            navigate('/customer/dashboard');
        } catch (error) {
            setErrorMessage(error.message);
            console.log(error);
        }

        setLoading(false);
    }

    return (
        <>
        <div className="card">
            <div className="card-title">
                Create your free account now.
                <p className="subtitle">It takes less than a minute to sign up.</p>
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            </div>
            <div className="card-form">
                <form id="new_user" onSubmit={handleSubmit}>
                    <div className="input-block">
                        <input type="email" ref={emailRef} id="email" required spellCheck="false" />
                        <label className="placeholder" htmlFor="email">Email Address *</label>
                    </div>
                    <div className="input-block">
                        <input type="password" ref={passwordRef} id="password" required spellCheck="false" />
                        <label className="placeholder" htmlFor="password">Password *</label>
                    </div>
                    <div className="input-block">
                        <p className="subtext">Your password needs to be at least 8 characters. Contain a number (0-9) or special character (!@#$%^&*).</p>
                        <p className="subtext">Agree to Terms &amp; Conditions.</p>
                    </div>
                    <button disabled={loading} type="submit" className="btn btn-block btn-primary">Get Started!</button>
                </form>
            </div>
        </div>
        <div>
            <p className="subtext">Already have an account? <Link to="/login">Sign In</Link></p>
        </div>
        </>
    );
}
