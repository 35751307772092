// 1. React Imports:
import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import { Button, IconButton, TextField } from "@mui/material";

// 3. Custom Hooks:

// 4. Component Imports:

// 5. Stylesheets:

// 6. Utility Functions or Constants:

// 7. Assets:
import CircularProgress from "@mui/material/CircularProgress";
import MenuIcon from "@mui/icons-material/Menu";

export function CustomerAccount({ data, handleDrawerToggle, handleUpdateAccount }) {
  /*--------------------------------------
		State Variables and Hooks
	-------------------------------------*/
  const [formData, setFormData] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [loading, setLoading] = useState(true);

  /*--------------------------------------
		  Event Handlers
	  -------------------------------------*/

  // Function to reset the form to initial data
  const handleCancel = () => {
    setFormData({
      account_name: data?.account_name || "",
      email: data?.email || "",
    });
    setFormChanged(false);
  };

  // Update formChanged state when input values change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setFormChanged(true);
  };

  // Save changes
  const handleSave = (event) => {
    event.preventDefault();
  
    const { account_name, email } = formData;
  
    // Prepare the update data, excluding password fields if they are empty
    const updateData = {
		account_name,
      email,
    };
  
    handleUpdateAccount(updateData);
  };

  /*--------------------------------------
    Use effect for initial data load
    -------------------------------------*/
	useEffect(() => {
		setFormData({
			account_name: data?.account_name || "",
			email: data?.email || "",
		});
		setLoading(false);
	  }, [data]);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <CircularProgress />
        </div>
      ) : (
        <div className="flex-auto px-6 pb-12 pt-9 md:p-8 md:pb-12 lg:p-12">
          <div className="flex items-center">
            <IconButton
              className="-ml-2 lg:hidden mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base"
              onClick={handleDrawerToggle}
              aria-label="Toggle drawer"
            >
              <MenuIcon />
            </IconButton>
            <div className="ml-2 text-3xl font-bold leading-none tracking-tight lg:ml-0">
              Account
            </div>
          </div>
          <div className="mt-8">
            <div id="settings-account">
              <div className="w-full max-w-3xl">
                <form noValidate="" onSubmit={handleSave}>
                  <div className="w-full">
                    <div className="text-xl">Account Profile</div>
                    <div className="text-secondary">
                      Following information is publicly displayed, be careful!
                    </div>
                  </div>
                  <div className="mt-8 grid w-full gap-6 sm:grid-cols-4">
                    <div className="sm:col-span-4">
                      <label
                        id="mat-mdc-form-field-label-52"
                        htmlFor="password"
                      >
                        Account name
                      </label>
                      <TextField
                        id="account_name"
                        variant="outlined"
                        className="mat-mdc-input-element mat-mdc-form-field-input-control mdc-text-field__input"
                        fullWidth
                        value={formData.account_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="my-10 border-t"></div>
                  <div className="w-full">
                    <div className="text-xl">Contact Information</div>
                    <div className="text-secondary">
                      Communication details in case we want to connect with you.
                    </div>
                  </div>
                  <div className="mt-8 grid w-full gap-6 sm:grid-cols-4">
                    <div className="sm:col-span-4">
                      <label
                        id="mat-mdc-form-field-label-52"
                        htmlFor="password"
                      >
                        Email
                      </label>
                      <TextField
                        id="email"
                        variant="outlined"
                        fullWidth
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="my-10 border-t"></div>
                  <div className="flex items-center justify-end">
                    <Button
                      variant="outlined"
                      className="mdc-button mdc-button--outlined mat-mdc-outlined-button mat-unthemed mat-mdc-button-base"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
					  type="submit"
                      className="ml-4 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                      disableElevation
                      disabled={!formChanged}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
