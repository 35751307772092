import React, { useEffect, useState, useRef } from "react";
//import button from MUI
import { Button, Grid } from "@mui/material";
import { motion } from "framer-motion";
import {
  FaRecycle,
  FaWater,
  FaLeaf,
  FaCar,
  FaBolt,
  FaMoneyBillWave,
  FaShower,
  FaTrash,
  FaPrint
} from "react-icons/fa";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export function EnvironmentalStats({ totalUnits }) {
  console.log("EnvironmentalStats totalUnits", totalUnits);
  const [enviroStats, setEnviroStats] = useState({
    water: 0,
    shower: 0,
    landfill: 0,
    climate: 0,
    driving: 0,
    energy: 0,
    tv: 0,
    refund: 0,
    totalUnits: 0,
  });
  const enviroCalculator = (totalUnits) => {
    setEnviroStats({
      water: totalUnits * 5.874162,
      shower: totalUnits * 5.874162 * 0.11107961727,
      landfill: totalUnits * 0.06,
      climate: totalUnits * 0.120368,
      driving: totalUnits * 0.120368 * 3.948406929,
      energy: totalUnits * 1.30388,
      tv: totalUnits * 1.30388 * 1.049176305,
      refund: totalUnits * 0.1,
      totalUnits: totalUnits,
    });
  };
  const formatCurrency = (totalUnits) => {
    return totalUnits?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const formatNumber = (number, nbrDecimals = 0) => {
    return number?.toLocaleString('en-US', {
      minimumFractionDigits: nbrDecimals,
      maximumFractionDigits: nbrDecimals
    });
  };
  useEffect(() => {
    enviroCalculator(totalUnits);
  }, [totalUnits]);

  const [logo, setLogo] = useState(null);
  const contentRef = useRef(null);
  const printRef = useRef(null);

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setLogo(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const generatePDF = () => {
    const content = printRef.current;
    html2canvas(content, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('environmental_impact.pdf');
    });
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <Button 
        onClick={generatePDF}
        variant="text"
      >
        <FaPrint style={{marginRight:"1rem"}} /> Print PDF
      </Button>

      <div ref={printRef} style={{ 
        backgroundColor: '#f0f0f0', 
        width: '100%', 
        minHeight: 'calc(100vh - 60px)',
        padding: '20px',
        boxSizing: 'border-box'
      }}>
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <h1 style={{ fontSize: '36px', color: '#333', marginBottom: '10px' }}>Environmental Impact</h1>
          <div style={{ fontSize: '48px', fontWeight: 'bold', color: '#1e40af' }}>
            {formatNumber(enviroStats.totalUnits)} containers recycled
          </div>
        </div>
        
        <Grid container spacing={3} justifyContent="center" style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <Grid item xs={12} md={6}>
            <StatCard
              icon="🌿"
              title="Carbon emissions avoided"
              mainStat={`${formatNumber(enviroStats.climate * 1000)} Kg of CO2`}
              equivalentTitle="Cars off the road for a year"
              bottomIcon="🚗"
              equivalentStat={`${formatNumber(Math.floor((enviroStats.climate * 1000) / 2700))} cars`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StatCard
              icon="💧"
              title="Water savings"
              mainStat={`${formatNumber(enviroStats.water)} litres`}
              equivalentTitle="Enough water to fill"
              bottomIcon="🏊‍♂️"
              equivalentStat={`${formatNumber(Math.floor(enviroStats.water / 2500000))} Olympic swimming pools`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StatCard
              icon="🗑️"
              title="Landfill savings"
              mainStat={`${formatNumber(enviroStats.landfill * 1000)} Kilograms`}
              equivalentTitle="Enough waste to fill"
              bottomIcon="🚛"
              equivalentStat={`${formatNumber(Math.floor((enviroStats.landfill * 1000) / 9525))} garbage trucks`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StatCard
              icon="⚡"
              title="Energy savings"
              mainStat={`${formatNumber(enviroStats.energy)} Megajoules`}
              equivalentTitle="Households' annual energy consumption"
              bottomIcon="🏠"
              equivalentStat={`${formatNumber(Math.floor(enviroStats.energy / 124.61))} years`}
            />
          </Grid>
         
          
        </Grid>
      </div>
    </div>
  );
}

function StatCard({ icon, title, mainStat, equivalentTitle, equivalentStat, bottomIcon }) {
  return (
    <div style={{ 
      backgroundColor: 'white', 
      borderRadius: '8px', 
      padding: '20px', 
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    }}>
      <div style={{ fontSize: '48px', marginBottom: '10px' }}>{icon}</div>
      <h2 style={{ fontSize: '24px', color: '#333', marginBottom: '10px' }}>{title}</h2>
      <p style={{ fontSize: '36px', fontWeight: 'bold', color: '#22c55e', marginBottom: '20px' }}>{mainStat}</p>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', margin: '10px 0' }}>
        <hr style={{ flex: 1, border: 'none', borderTop: '1px solid #ccc' }} />
        <span style={{ padding: '0 10px', color: '#666', fontSize: '16px' }}>Which is equivalent to</span>
        <hr style={{ flex: 1, border: 'none', borderTop: '1px solid #ccc' }} />
      </div>
      <div style={{ fontSize: '48px', marginBottom: '10px' }}>{bottomIcon}</div>
      <p style={{ fontSize: '18px', color: '#333', fontWeight: 'bold' }}>{equivalentTitle}</p>
      <p style={{ fontSize: '24px', color: '#eab308', fontWeight: 'bold' }}>{equivalentStat}</p>
    </div>
  );
}
