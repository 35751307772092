import { useState, useEffect, useCallback } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from "@react-google-maps/api";
import { req } from "utils/api";
import { DateRangePicker } from "react-date-range";
import { addDays, format } from "date-fns";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { debounce } from "lodash"; // Ensure lodash is installed
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {StatsMap} from "components/analytics/StatsMap";
import { GoogleMapsLoader } from "../../components/analytics/GoogleMapsLoader";

const mapContainerStyle = {
  width: "100%",
  height: "600px", // Increased height for better visibility
};

// Default center set to Melbourne
const defaultCenter = { lat: -37.8136, lng: 144.9631 };

export function AdminAnalyticsMaps() {
  const [startDate, setStartDate] = useState(addDays(new Date(), -37));
  const [endDate, setEndDate] = useState(addDays(new Date(), -7));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSelect = (ranges) => {
    let { startDate, endDate } = ranges.selection;
    const maxEndDate = addDays(new Date(), -7);

    if (endDate > maxEndDate) {
      endDate = maxEndDate;
    }

    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));
  };

  const handleOpenPopover = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "date-range-popover" : undefined;

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  return (
    <GoogleMapsLoader>
      <div className="flex flex-col flex-auto">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-4">Analytics: Interactive Maps</h1>
          <Box className="w-full relative" sx={{ p: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                The data available is until 7 days before the current day
              </Grid>
              <Grid item xs={6}>
                <Button variant="text" fullWidth startIcon={<CalendarTodayIcon />} onClick={handleOpenPopover}>
                  {`${format(startDate, "MMM dd, yyyy")} - ${format(endDate, "MMM dd, yyyy")}`}
                </Button>
              </Grid>
            </Grid>

            <Popover
              id={popoverId}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
                maxDate={addDays(new Date(), -7)}
              />
            </Popover>
          </Box>

          <StatsMap role="admin" startDate={startDate} endDate={endDate} />
        </div>
      </div>
    </GoogleMapsLoader>
  );
}
