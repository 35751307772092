// 1. React Imports:
import { useState, useEffect } from "react";

// 2. Third-Party Library Imports:
import {
  Box,
  Button,
  Chip,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";

// 3. Component Imports:
import { CreateUpdateCustomer } from "../../components/customers/CreateUpdateCustomer";
import { SnackbarNotification } from "../../components/SnackbarNotification";
import DataTable from "../../components/DataTable";

// 6. Utility Functions or Constants:
import { req } from "utils/api";

// 7. Assets:
import AddIcon from "@mui/icons-material/Add";
import GroupsIcon from "@mui/icons-material/Groups";

// --------------------------------------------------------------

export function AdminCustomers() {
  /*-------------------------------------
  State Variables and Hooks
	-------------------------------------*/
  const [customer, setCustomer] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedFilters, setSelectedFilters] = useState([]); // State for selected filters

  /*-------------------------------------
		Derived Data and Functions
	-------------------------------------*/
  const filterOptions = [
    "LGA Contact",
    "Property Manager",
    "Redemption Partner",
    "Site Contact",
  ];

  const columns = [
    {
      field: "account_id",
      headerName: "ID",
    },
    {
      field: "account_name",
      headerName: "Account",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {value}
          {row.additional_logins.length > 0 && (
            <Chip
              size="small"
              className="ml-4 pl-2 pr-1"
              variant="outlined"
              label={row.additional_logins.length}
              icon={<GroupsIcon />}
            />
          )}
        </div>
      ),
    },
    {
      field: "account_locations",
      headerName: "Locations/Sites",
      formatter: (value, row) => {
        const locationTypes = {};

        row.account_locations.forEach((location) => {
          if (!locationTypes[location.location_relationship]) {
            locationTypes[location.location_relationship] = 1;
          } else {
            locationTypes[location.location_relationship]++;
          }
        });

        return (
          <Stack direction="row" spacing={1}>
            {Object.entries(locationTypes).map(([type, count]) => (
              <Chip
                key={type}
                label={`${type}: ${count}`}
                size="small"
                color="primary"
                variant="outlined"
              />
            ))}
          </Stack>
        );
      },
    },

    // other columns if needed
  ];

  /*-------------------------------------
    	Utility Functions
  -------------------------------------*/

  const closeDataDrawer = () => {
    setDrawerOpen(false);
  };

  const reloadCustomers = () => {
    // Trigger a refresh by incrementing the counter
    setRefreshCounter((prev) => prev + 1);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  /*-------------------------------------
		Event Handlers
	-------------------------------------*/
  const handleFilterChange = (event) => {
    const { value } = event.target;
    setSelectedFilters(typeof value === "string" ? value.split(",") : value);
    setRefreshCounter((prevCounter) => prevCounter + 1);
  };

  const handleClickAddUpdateCustomer = (customer = {}) => {
    setCustomer(customer); // Set the customer to be edited
    setDrawerOpen(true); // Open the drawer
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  
  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    document.title = "Manage Customers";
  }, []);


  /*-------------------------------------
		Async Functions
	-------------------------------------*/

  const createCustomer = async (data) => {
    try {
      const response = await req("POST", "admin/create_customer", data);
      if (response.success === true) {
        setSnackbarMessage("Customer created successfully.");
        setSnackbarSeverity("success");
        reloadCustomers();
        setDrawerOpen(false);
        return response.result;
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      setSnackbarMessage(`Failed to create customer: ${error}`);
      setSnackbarSeverity("error");
      return false;
    } finally {
      setOpenSnackbar(true);
    }
  };

  const updateCustomer = async (postData) => {
    try {
      const response = await req("POST", "admin/update_customer", postData);

      if (response.success === true) {
        setSnackbarMessage("Customer updated successfully.");
        setSnackbarSeverity("success");
        reloadCustomers();
        setDrawerOpen(false);
        return true;
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error(`Failed to update customer: ${error}`);
      setSnackbarMessage(`Failed to update customer.`);
      setSnackbarSeverity("error");
      return false;
    } finally {
      setOpenSnackbar(true);
    }
  };

  const deleteAccount = async (account_id) => {
    try {
      const response = await req("POST", "admin/delete_account", {
        id: account_id,
      });
      if (response.success === true) {
        setSnackbarMessage("Account deleted successfully.");
        setSnackbarSeverity("success");
        reloadCustomers();
        setDrawerOpen(false);
        return true;
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      setSnackbarMessage(`Failed to delete account: ${error}`);
      setSnackbarSeverity("error");
      return false;
    } finally {
      setOpenSnackbar(true);
    }
  };

  const addLocation = async (data) => {
    try {
      const response = await req("POST", "admin/add_account_to_location", data);

      if (response.success === true) {
        setSnackbarMessage("Location added successfully.");
        setSnackbarSeverity("success");
        reloadCustomers();
        return response;
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to add location:", { error });
      setSnackbarMessage(`Failed to add location.`);
      setSnackbarSeverity("error");
      return false;
    } finally {
      setOpenSnackbar(true);
    }
  };

  const removeLocation = async (data) => {
    try {
      const response = await req(
        "post",
        "admin/remove_account_from_location",
        data
      );

      if (response.success === true) {
        setSnackbarMessage("Location removed successfully.");
        setSnackbarSeverity("success");
        reloadCustomers();
        return true;
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to remove location:", { error });
      setSnackbarMessage(`Failed to remove location`);
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const createTeamMember = async (data) => {
    try {
      const response = await req("POST", "admin/create_team_member", data);

      if (response.success === true) {
        setSnackbarMessage("Team Member created successfully.");
        setSnackbarSeverity("success");
        reloadCustomers();
        return response.userId;
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to create team member:", { error });
      setSnackbarMessage(`Failed to create team member`);
      setSnackbarSeverity("error");
      return false;
    } finally {
      setOpenSnackbar(true);
    }
  };

  const deleteTeamMember = async (data) => {
    try {
      const response = await req("POST", "admin/delete_user", data);

      if (response.success === true) {
        setSnackbarMessage("Team Member deleted successfully.");
        setSnackbarSeverity("success");
        reloadCustomers();
        return true;
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to delete team member:", { error });
      setSnackbarMessage(`Failed to delete team member`);
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };


  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "600px",
          },
        }}
      >
        <CreateUpdateCustomer
          customer={customer}
          closeDataDrawer={closeDataDrawer}
          createCustomer={createCustomer}
          updateCustomer={updateCustomer}
          deleteAccount={deleteAccount}
          addLocation={addLocation}
          removeLocation={removeLocation}
          createTeamMember={createTeamMember}
          deleteTeamMember={deleteTeamMember}
        />
      </Drawer>
      <Box className="flex flex-auto flex-col w-full relative" sx={{ p: 3 }}>
        <Box
          className="flex flex-auto flex-col w-full relative"
          sx={{ backgroundColor: "#fff", borderRadius: 2, p: 3, boxShadow: 1 }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                Customers
              </h1>
              <div className="subtitle">Review Customer Accounts</div>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
              <Stack
                direction={{ xs: "column-reverse", lg: "row" }}
                spacing={2}
                justifyContent={{ xs: "flex-start", lg: "flex-end" }}
              >
                <FormControl sx={{ minWidth: 200 }} size="small">
                  <InputLabel size="small">Location Relationship Type</InputLabel>
                  <Select
                    multiple
                    value={selectedFilters}
                    onChange={handleFilterChange}
                    input={<OutlinedInput label="Location Relationship Type" />}
                    renderValue={(selected) => selected.join(", ")}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "20px", // Apply the rounding here
                      },
                      "& .MuiOutlinedInput-input": {
                        fontSize: "0.875rem", // Adjust font size for small size
                        textAlign: "left",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "20px", // Apply the border rounding here
                      },
                    }}
                  >
                    {filterOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  className="mdc-button mat-mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleClickAddUpdateCustomer}
                  disableElevation
                  sx={{ width: { xs: "100%", lg: "auto" } }}
                >
                  Add Customer
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <DataTable
              url={`admin/get_accounts?type=customer${
                selectedFilters.length > 0
                  ? `&filterLocation=${selectedFilters.join(",")}`
                  : ""
              }`}
              columns={columns}
              paginationType="server"
              initialPageSize={10}
              searchEnabled={true}
              refresh={refreshCounter} // Pass the refresh counter as a prop
              rowClickAction={handleClickAddUpdateCustomer}
            />
          </Box>
        </Box>
      </Box>
      <SnackbarNotification
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
}
