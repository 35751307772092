import React, { useState, useEffect, useCallback, memo } from "react";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from "@react-google-maps/api";
import { req } from "utils/api";

const mapContainerStyle = {
  width: "100%",
  height: "600px",
};

const defaultCenter = { lat: -37.8136, lng: 144.9631 };

// Memoized GoogleMap component
const MemoizedGoogleMap = memo(({ children, ...props }) => (
  <GoogleMap {...props}>{children}</GoogleMap>
));

export function StatsMap({ role, startDate, endDate }) {
  const [map, setMap] = useState(null);
  const [deposits, setDeposits] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getDeposits = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    const startDateString = startDate.toISOString().split("T")[0];
    const endDateString = endDate.toISOString().split("T")[0];
    const url = role === "admin" 
      ? `admin/get_map_deposits?startDate=${startDateString}&endDate=${endDateString}`
      : `customer/get_map_deposits?startDate=${startDateString}&endDate=${endDateString}`;

    try {
      const res = await req("GET", url);
      if (res.success) {
        setDeposits(res.data);
        setIsDataLoaded(true);
      } else {
        setError(res.message || "Failed to fetch data");
      }
    } catch (err) {
      setError(err.message || "An error occurred");
    }
    setIsLoading(false);
  }, [startDate, endDate, role]);

  useEffect(() => {
    getDeposits();
  }, [getDeposits]);

  return (
    <div className="flex flex-col flex-auto">
      {isLoading && <div className="loader">Loading...</div>}
      {error && <div className="error text-red-500">{error}</div>}
      {!isLoading && !error && isDataLoaded && deposits.length === 0 && (
        <div className="text-center py-4 text-gray-600">
          No deposits found for the selected date range.
        </div>
      )}
      <MemoizedGoogleMap
        mapContainerStyle={mapContainerStyle}
        center={defaultCenter}
        zoom={7}
        onLoad={(mapInstance) => setMap(mapInstance)}
        onUnmount={() => setMap(null)}
        options={{
          zoomControl: true,
        }}
      >
        {isDataLoaded && (
          <MarkerClusterer>
            {(clusterer) => (
              <>
                {deposits.map((data) => {
                  const depositsCount = parseInt(data.total_deposits);
                  const maxDeposits = Math.max(
                    ...deposits.map((d) => parseInt(d.total_deposits))
                  );
                  let color = "#00FF00";
                  if (depositsCount > 0.66 * maxDeposits) {
                    color = "#FF0000";
                  } else if (depositsCount > 0.33 * maxDeposits) {
                    color = "#FFA500";
                  }

                  return (
                    <Marker
                      key={data.location_g_id}
                      position={{
                        lat: parseFloat(data.latitude),
                        lng: parseFloat(data.longitude),
                      }}
                      clusterer={clusterer}
                      onClick={() => setSelectedMarker(data)}
                      icon={{
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 50,
                        fillColor: color,
                        fillOpacity: 0.8,
                        strokeColor: "#FFFFFF",
                        strokeWeight: 1,
                      }}
                    />
                  );
                })}

                {selectedMarker &&
                  selectedMarker.total_deposits > 0 &&
                  selectedMarker.latitude !== 0 &&
                  selectedMarker.longitude !== 0 && (
                    <InfoWindow
                      position={{
                        lat: parseFloat(selectedMarker.latitude),
                        lng: parseFloat(selectedMarker.longitude),
                      }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div>
                        <h2 className="font-bold">
                          {selectedMarker.location_name}
                        </h2>
                        <p>
                          <strong>Deposits:</strong>{" "}
                          {parseInt(
                            selectedMarker.total_deposits
                          ).toLocaleString()}
                        </p>
                        <p>
                          <strong>ID:</strong> {selectedMarker.location_g_id}
                        </p>
                      </div>
                    </InfoWindow>
                  )}
              </>
            )}
          </MarkerClusterer>
        )}
      </MemoizedGoogleMap>
    </div>
  );
}
