// 1. React Imports:
import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

// 3. Component Imports:
import { AccountManagement } from "./AccountManagement";

// 6. Utility Functions or Constants:
import { req } from "utils/api";

// 7. Assets:
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddLinkIcon from "@mui/icons-material/AddLink";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";



export function CreateEditLocation({
  location = {},
  reloadTable,
  closeDataDrawer,
  handleLocationCreated,
  handleLocationUpdated,
  createLocation,
  updateLocation,
  deleteLocation,
  createLinkedAccount,
  deleteLinkedAccount,
}) {
  /*--------------------------------------
    State Variables
  -------------------------------------*/
  const [locationId, setLocationId] = useState(location?.location_id || null);
  const [locationName, setLocationName] = useState(null);
  const [locationGid, setLocationGid] = useState(null);
  const [locationAddress, setLocationAddress] = useState(null);
  const [locationAccounts, setLocationAccounts] = useState([]);
  const [showLinkAccount, setShowLinkAccount] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [searchAccounts, setSearchAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [openRelationshipDialog, setOpenRelationshipDialog] = useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState("");

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [refreshCounter, setRefreshCounter] = useState(0);
  const [formProcessing, setFormProcessing] = useState(false);

  /*--------------------------------------
    Utility Functions
  -------------------------------------*/

  const validateForm = () => {
    let formErrors = {};

    if (!locationName) {
      formErrors.name = "Location name is required.";
    }
    if (!locationGid) {
      formErrors.locationGid = "Location GID is required.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };


  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleToggleLinkAccount = () => {
    setShowLinkAccount((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      setFormProcessing(true);

      const data = {
        location_name: locationName,
        location_g_id: locationGid,
        address: locationAddress,
      };

      if (location.location_id) {
        await updateLocation({
          id: location.location_id,
          data,
        });
      } else {
        await createLocation(data);
      }
    } catch (error) {
      console.error("Failed to submit form:", error.message);
    } finally {
      setFormProcessing(false);
    }
  };

  const handleHideLinkAccount = () => {
    document.getElementById("linkAccount").classList.add("hidden");
  };

  // const handleAccountSearch = async (searchTerm) => {
  //   if (searchTerm && accounts.length > 0) {
  //     //console.log("Searching for:", searchTerm, allLocations);
  //     setSearchAccounts(
  //       accounts.filter((item) =>
  //         item.account_name.toLowerCase().includes(searchTerm.toLowerCase())
  //       )
  //     );
  //   } else {
  //     setSearchAccounts([]);
  //   }
  // };

  // const handleAccountSelect = (event, newValue) => {
  //   if (newValue) {
  //     setSelectedAccount(newValue);
  //     setOpenRelationshipDialog(true);
  //   }
  // };

  const handleRelationshipSelect = async () => {
    if (selectedAccount && selectedRelationship) {
      try {
        let response = await req("post", "admin/add_account_to_location", {
          account_id: selectedAccount.account_id,
          location_id: location.location_id,
          relationship: selectedRelationship,
        });

        handleHideLinkAccount();

        setOpenRelationshipDialog(false);
        setSelectedAccount(null);
        setSelectedRelationship("");
        reloadTable();
        // setLocationAccounts(location?.accounts_with_access);
      } catch (error) {
        console.error("Error associating location:", error);
        alert("Failed to associate location.");
      } finally {
        setRefreshCounter((prev) => prev + 1);
      }
    }
  };

  const handleCreateLinkedAccount = async () => {
    if (!selectedAccount || !selectedRelationship) {
      return false;
    }

    const postData = {
      account_id: selectedAccount.account_id,
      location_id: location.location_id,
      relationship: selectedRelationship,
    };

    const response = await createLocation(postData);

    if (response) {
      // const result = response.newRecord;
      // const newLocation = {
      //   account_location_id: result.account_location_id,
      //   location_id: result.location_id,
      //   location_name: result.location_name,
      //   location_relationship: result.location_relationship,
      // };

      // // Update the state with the new location
      // const updatedLocations = [...locations, newLocation];
      // updateLocations(updatedLocations);

      setOpenRelationshipDialog(false);
      setSelectedAccount(null);
      setSelectedRelationship("");
      handleToggleLinkAccount();
    }
  };

  const handleDeleteLinkedAccount = async (accountToDelete) => {
    
    const data = {
      account_id: accountToDelete.account_id,
      location_id: location.location_id,
    }
    deleteLinkedAccount(data);
    setRefreshCounter((prev) => prev + 1); // Force the drawer to refresh
  };

  const handleClickDeleteLocation = () => {
    if (window.confirm("Are you sure you want to delete this location?")) {
      const locationId = location.location_id;
      deleteLocation(locationId);
    }
  };

  const handleUpdateLinkedAccounts = (accounts) => {
    console.log("Updating linked accounts:", accounts);
    setLocationAccounts(accounts);
  };

  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    if (location) {
      setLocationName(location.location_name || "");
      setLocationGid(location.location_g_id || "");
      setLocationAddress(location.address || "");
      setLocationAccounts(
        (location.accounts_with_access || []).filter(account =>
          Object.values(account).some(value => value !== null)
        )
      );
    }
    getAccounts();
  }, [location, refreshCounter]);


  /*-------------------------------------
		Async Functions
	-------------------------------------*/

  async function getAccounts() {
    try {
      let result = await req("get", "admin/get_accounts?type=customer");
      setAccounts(result.data.rows);
      //   console.log("Accounts:", result.data); // Debugging
    } catch (error) {
      console.error("Failed to read account locations:", error);
    }
  }

  console.log("Location:", location); // Debugging

  return (
    <>
      <div className="relative w-full px-8 sm:px-12">
        <div className="mx-auto flex w-full max-w-3xl items-start justify-between py-6">
          <div>
            <div className="text-4xl font-bold">
              {location.location_id ? "Edit Location" : "Add New Location"}
            </div>
          </div>
          <div className="flex items-center mt-1 ml-auto">
            <IconButton onClick={closeDataDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="relative flex flex-auto flex-col items-center p-6 py-0 sm:p-12 sm:py-0">
        <div className="w-full max-w-3xl border-t">
          {loading ? (
            <div className="flex justify-center items-center py-16">
              <CircularProgress />
            </div>
          ) : (
            <form noValidate>
              <div className="mt-8">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="location_name">Location Name</label>
                  <TextField
                    id="location_name"
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="location_email">Location GID</label>
                  <TextField
                    id="location_gid"
                    value={locationGid}
                    onChange={(e) => setLocationGid(e.target.value)}
                    error={!!errors.locationGid}
                    helperText={errors.locationGid}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Grid4x4Icon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="location_address">Location Address</label>
                  <TextField
                    id="location_address"
                    value={locationAddress}
                    onChange={(e) => setLocationAddress(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PlaceOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              {location.location_id && (
                <>
                  <Divider className="mt-8" />
                  <AccountManagement
                    location={location}
                    linked_accounts={locationAccounts}
                    createLinkedAccount={createLinkedAccount}
                    deleteLinkedAccount={deleteLinkedAccount}
                    updateLinkedAccounts={handleUpdateLinkedAccounts}
                  />
                </>
              )}
              {/* {locationAccounts.length > 0 &&
              locationAccounts[0].account_id !== null ? (
                <div className="mt-4">
                  <div className="flex flex-auto flex-col">
                    <LinkedAccountsTable
                      data={locationAccounts}
                      handleDeleteLinkedAccount={handleDeleteLinkedAccount}
                    />
                  </div>
                </div>
              ) : null} */}
              <div className="-mx-6 mt-10 flex items-center border-t bg-gray-50 py-4 pl-1 pr-4 dark:bg-transparent sm:-mx-12 sm:pl-7 sm:pr-12">
                {location.location_id && (
                  <Button
                  className="mdc-button mat-mdc-button mat-warn mat-mdc-button-base"
                  onClick={handleClickDeleteLocation}
                  disabled={formProcessing}
                >
                  Delete
                </Button>
                )}
                <Button
                  className="ml-auto mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
                  onClick={closeDataDrawer}
                  disabled={formProcessing}
                >
                  Cancel
                </Button>
                <Button
                  className="ml-2 mdc-button mat-mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={formProcessing}
                  disableElevation
                >
                  {formProcessing
                    ? location.location_id
                      ? "Updating..."
                      : "Creating..."
                    : location.location_id
                      ? "Update Location"
                      : "Create Location"}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
      {/* <Dialog
        open={openRelationshipDialog}
        onClose={() => setOpenRelationshipDialog(false)}
      >
        <DialogTitle>Select Relationship Type</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={selectedRelationship}
            onChange={(e) => setSelectedRelationship(e.target.value)}
          >
            {[
              "Site Contact",
              "Property Manager",
              "LGA Contact",
              "Redemption Partner",
            ].map((type) => (
              <FormControlLabel
                key={type}
                value={type}
                control={<Radio />}
                label={type}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRelationshipDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleCreateLinkedAccount} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
