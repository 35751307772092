import React,{useEffect, useState} from 'react';
import DataTable from "../../components/DataTable"; // Adjust the import path as necessary
import { format } from 'date-fns';

export function ContainersTable({ startDate, endDate, role }) {
  const locationFormatter = (value,row) => {
    if (value) {
      return value;
    } else {
      return 'No location in database, G-ID: ' + row.location_id;
    }
  }
  const columns = [
    { field: 'deposit_date', headerName: 'Date', width: 150, formatter: (value) => format(new Date(value), 'MM/dd/yyyy') },
    { field: 'location_name', headerName: 'Location', width: 100, formatter:locationFormatter, cellClassName: 'center-align' },
    //{ field: 'machine_id', headerName: 'Machine ID', width: 150, cellClassName: 'center-align' },
    { field: 'material', headerName: 'Material', width: 100, cellClassName: 'center-align' },
    { field: 'count', headerName: 'Count', width: 100, cellClassName: 'center-align' },
  ];
  
  const formattedStart = encodeURIComponent(startDate.toISOString().split('T')[0]);
  const formattedEnd = encodeURIComponent(endDate.toISOString().split('T')[0]);
  const baseUrl = role === 'admin' ? 'admin' : 'customer';
  const [url, setUrl] = useState(`${baseUrl}/get_deposits?startDate=${formattedStart}&endDate=${formattedEnd}`);
  useEffect(() => {
    setUrl(`${baseUrl}/get_deposits?startDate=${formattedStart}&endDate=${formattedEnd}`);
  }, [startDate, endDate]);
  return (

      <DataTable
        url={url}
        columns={columns}
        paginationType="server"
        initialPageSize={10}
        searchEnabled={false} // Disable search if it's not needed
      />
  );
}
