// 1. React Imports:
// import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import { IconButton } from "@mui/material";

// 3. Custom Hooks:

// 4. Component Imports:

// 5. Stylesheets:

// 6. Utility Functions or Constants:

// 7. Assets:
import CloseIcon from "@mui/icons-material/Close";

// --------------------------------------------------------------

const RenderLogObject = ({ log }) => {
    // Recursive function to render key-value pairs
    const renderObject = (obj) => {
      return (
        <ul>
          {Object.entries(obj).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong>{' '}
              {typeof value === 'object' && value !== null ? (
                // Recursively call the function if the value is an object or array
                <div style={{ marginLeft: '20px' }}>{renderObject(value)}</div>
              ) : (
                // If it's a primitive value, just display it
                value?.toString()
              )}
            </li>
          ))}
        </ul>
      );
    };
  
    return <div>{renderObject(log)}</div>;
  };

// --------------------------------------------------------------

export function LogDetails({ log, closeDataDrawer }) {
  /*-------------------------------------
		State Variables and Hooks
  -------------------------------------*/
  /*-------------------------------------
		Derived Data and Functions
  -------------------------------------*/
  /*-------------------------------------
    	Utility Functions
  -------------------------------------*/
  /*-------------------------------------
		Event Handlers
  -------------------------------------*/
  /*-------------------------------------
		Effects
  -------------------------------------*/
  /*-------------------------------------
		Async Functions
  -------------------------------------*/

  // --------------------------------------------------------------
  console.log("LogDetails -> log", log);

  return (
    <>
      <div className="relative w-full px-8 sm:px-12">
        <div className="mx-auto flex w-full max-w-3xl items-start justify-between py-6">
          <div>
            <div className="text-4xl font-bold">Log Entry Details</div>
          </div>
          <div className="flex items-center mt-1 ml-auto">
            <IconButton onClick={closeDataDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="relative flex flex-auto flex-col items-center p-6 py-0 sm:p-12 sm:py-0">
        <div className="w-full max-w-3xl border-t h-full">
          <div className="mt-8 word-wrap">
            <RenderLogObject log={log} />
          </div>
        </div>
      </div>
    </>
  );
}
