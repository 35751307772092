import { req } from "./api";

export const log = async (message, data, log_level = "INFO") => {
  let buildId = 0;
  if (process.env.REACT_APP_BUILD_ID) buildId = process.env.REACT_APP_BUILD_ID;
  let logData = {
    buildId,
    message,
    stack: "",
    log_level,
    browser: navigator.userAgent,
    location: window.location.href,
    timestamp: new Date().toISOString(),
  };

  if (data instanceof Error) {
    logData.message = data.message;
    logData.stack = data.stack;
  } else if (typeof data === "string") {
    logData.message = data;
  } else if (typeof data === "object") {
    logData = { ...logData, ...data };
  }

  const logString = JSON.stringify(logData);

  if (process.env.NODE_ENV === "development") {
    console.log("DEV so only console logging", log_level, logString);
    req("post", "log", { log_level, message, data: logData }); //todo remove
  } else {
    req("post", "log", { log_level, message, data: logData });
  }

};
