import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import useAuth from '../features/authentication/hooks/useAuth';

const PublicRoute = () => {
  const { validateAndRedirect, isAuthenticated, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && isAuthenticated) {
      validateAndRedirect(navigate);
    }
  }, [validateAndRedirect, isAuthenticated, loading, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Or your loading component
  }

  return <Outlet />;
};

export default PublicRoute;