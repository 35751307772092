import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format, eachDayOfInterval } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

export function DepositDailyBarChart({ materialStats }) {
  // Aggregate data by date and material type
  const aggregateDataByDateAndMaterial = (data) => {
    const aggregatedData = {};

    data.forEach(stat => {
      const date = format(new Date(stat.deposit_date), 'yyyy-MM-dd');
      if (!aggregatedData[date]) {
        aggregatedData[date] = {};
      }
      if (!aggregatedData[date][stat.material]) {
        aggregatedData[date][stat.material] = 0;
      }
      aggregatedData[date][stat.material] += parseInt(stat.total_units, 10); // Aggregate by total_units
    });

    return aggregatedData;
  };

  const aggregatedData = aggregateDataByDateAndMaterial(materialStats);

  // Extract unique materials
  const materials = Array.from(new Set(materialStats.map(stat => stat.material)));

  // Extract all dates in the range
  const allDates = eachDayOfInterval({
    start: new Date(Math.min(...materialStats.map(stat => new Date(stat.deposit_date)))),
    end: new Date(Math.max(...materialStats.map(stat => new Date(stat.deposit_date)))),
  }).map(date => format(date, 'yyyy-MM-dd'));

  // Predefined color palette to match the color scheme of your application
  const colorPalette = [
    '#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087',
    '#f95d6a', '#ff7c43', '#ffa600', '#636363', '#bc5090',
    '#58508d', '#ff6361', '#bc5090', '#ffa600', '#003f5c',
    '#8e7dbe', '#6a51a3', '#cb55a5', '#d45087', '#bb3b6d',
    '#833c7e', '#ff7c43', '#f6701f', '#bc5090', '#f85e7d',
    '#3f7f93', '#67a4dc', '#45aeb3', '#f7b8b3', '#fe8282',
    '#ec8a90', '#c94a53', '#d84436', '#ed681d', '#edae2b',
    '#ff7c43', '#83b5d7', '#89d8d3', '#92e595', '#beea72'
  ];

  // Prepare datasets
  const datasets = materials.map((material, index) => {
    return {
      label: material,
      data: allDates.map(date => aggregatedData[date] ? aggregatedData[date][material] || 0 : 0),
      backgroundColor: colorPalette[index % colorPalette.length], // Assign colors from the palette
    };
  });

  const barData = {
    labels: allDates.map(date => new Date(date)), // Dates for the x-axis
    datasets: datasets,
  };

  return (
    <div>
      <Bar 
        data={barData} 
        options={{
          plugins: {
            legend: {
              position: 'top',
            },
          },
          scales: {
            x: {
              type: 'time', // Ensure the x-axis is time-based
              time: {
                unit: 'day', // Show data per day
                tooltipFormat: 'dd/MM/yyyy', // Tooltip date format
                displayFormats: {
                  day: 'dd/MM/yyyy', // Axis label date format
                },
              },
              stacked: true, // Enable stacking on the x-axis
              ticks: {
                source: 'labels', // Ensure ticks are aligned with the labels
                autoSkip: false, // Do not skip any dates
                maxRotation: 0,
                callback: function(value) {
                  return format(new Date(value), 'dd/MM/yyyy'); // Format tick labels
                },
              },
            },
            y: {
              beginAtZero: true,
              stacked: true, // Enable stacking on the y-axis
            },
          },
        }} 
      />
    </div>
  );
}
