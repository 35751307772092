// 1. React Imports:
import { useState, useEffect } from "react";

// 2. Third-Party Library Imports:
import { Box, Button, Drawer, Grid } from "@mui/material";

// 3. Component Imports:
import { AdminCreateAdmin } from "../../components/accounts/AdminCreateAdmin";
import { AdminUpdateAdmin } from "../../components/accounts/AdminUpdateAdmin";
import { SnackbarNotification } from "../../components/SnackbarNotification";
import DataTable from "components/DataTable";

// 6. Utility Functions or Constants:
import { req } from "utils/api";

// 7. Assets:
import AddIcon from "@mui/icons-material/Add";

// --------------------------------------------------------------

export function AdminAdministrators() {
  /*--------------------------------------
    State Variables and Hooks
  -------------------------------------*/

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null); // To hold the content to be displayed in the drawer
  const [refreshCounter, setRefreshCounter] = useState(0); // State to trigger refresh
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");


  /*-------------------------------------
		Derived Data and Functions
	-------------------------------------*/

  const columns = [
    {
      field: "account_name",
      headerName: "Account",
    },
    // other columns if needed
  ];


  /*-------------------------------------
    	Utility Functions
  -------------------------------------*/

  const reloadAdministrators = () => {
    // Trigger a refresh by incrementing the counter
    console.log("Reloading customers data...");
    setRefreshCounter((prev) => prev + 1);
  };

  const closeDataDrawer = () => {
    setDrawerOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };


  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleClickAdd = () => {
    setDrawerContent(
      <AdminCreateAdmin
        closeDataDrawer={closeDataDrawer}
        createAdmin={createAdmin}
      />
    );
    setDrawerOpen(true);
  };

  const handleClickEdit = (admin) => {
    setDrawerContent(
      <AdminUpdateAdmin
        admin={admin}
        closeDataDrawer={closeDataDrawer}
        deleteAccount={deleteAccount}
        updateAccount={updateAccount}
      />
    );
    setDrawerOpen(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };


  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    document.title = "Manage Administrators";
  }, []);


  /*-------------------------------------
		Async Functions
	-------------------------------------*/
  
  const createAdmin = async (data) => {
    try {
      const response = await req("post", "admin/create_admin", data);
      if(response.success === true){
        setSnackbarMessage("Account created successfully.");
        setSnackbarSeverity("success");
        closeDataDrawer();
        reloadAdministrators();
      } else {
        // Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to create administrator:", {error});
      setSnackbarMessage(`Failed to create administrator.`);
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const updateAccount = async (data) => {
    try {
      const response = await req("post", "admin/update_account", data);
      if(response.success === true){
        setSnackbarMessage("Account updated successfully.");
        setSnackbarSeverity("success");
        reloadAdministrators();
        return true;
      } else {
        // Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to update account.", {error});
      setSnackbarMessage(`Failed to update account.`);
      setSnackbarSeverity("error");
      return false;
    } finally {
      setOpenSnackbar(true);
    }
  };

  const deleteAccount = async (data) => {
    try {
      const response = await req("post", "admin/delete_account", data);
      if(response.success === true){
        setSnackbarMessage("Account deleted successfully.");
        setSnackbarSeverity("success");
        reloadAdministrators();
        closeDataDrawer();
      } else {
        // Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to update account.", {error});
      setSnackbarMessage(`Failed to delete account.`);
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "600px",
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <Box className="flex flex-auto flex-col w-full relative" sx={{ p: 3 }}>
        <Box
          className="flex flex-auto flex-col w-full relative"
          sx={{ backgroundColor: "#fff", borderRadius: 2, p: 3, boxShadow: 1 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                Administrators
              </h1>
              <div className="subtitle">Review &amp; Manage Administrators</div>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                className="mdc-button mat-mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                startIcon={<AddIcon />}
                onClick={handleClickAdd}
              >
                Add Administrator
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <DataTable
              url="admin/get_accounts?type=admin"
              columns={columns}
              paginationType="server"
              initialPageSize={10}
              searchEnabled={true}
              refresh={refreshCounter} // Pass the refresh counter as a prop
              rowClickAction={handleClickEdit}
            />
          </Box>
        </Box>
      </Box>
      <SnackbarNotification
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
}
