import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useToast } from "contexts/ToastContext"; // Import the useToast hook
import { TextField, Button, Card, CardContent, Typography, Box, CircularProgress } from "@mui/material";

export function Login() {
  const navigate = useNavigate();
  const { login,user } = useAuth();
  const { showToast } = useToast(); // Get the showToast function from the context
  const emailRef = useRef();
  const passwordRef = useRef();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    try {
      let result = await login(email, password);
      showToast('Login successful', 'success'); // Show success toast
      console.log("user after login", result); // Log the result object

      let url = "/customer/home";
      if (result.accountType === "admin") {
        url = "/admin/dashboard";
      }
      if (result.accountType === "customer") {
        url = "/customer/home";
      }
      navigate(url);
    } catch (error) {
      showToast(error.message, 'error'); // Show error toast
    }
    setLoading(false);
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '100vh',
      paddingBottom: '10vh' // Add this line
    }}>
      <Card sx={{ maxWidth: 400, width: '100%' }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Welcome Back!
          </Typography>
          <form id="login_user" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Email Address"
              type="email"
              inputRef={emailRef}
              required
              disabled={loading}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type="password"
              inputRef={passwordRef}
              required
              disabled={loading}
            />
            <Box sx={{ position: 'relative', mt: 2 }}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Login
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </form>
          <Typography variant="body2" sx={{ mt: 2 }}>
            <Link to="/forgot">Forgot Password</Link>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
